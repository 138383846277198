<template>
    <img :src="listIconURL" :class="size" />
</template>
<script>
export default {
    data() {
        return {
        };
    },
    computed: {
        listIconURL() {
            return window.location.origin + ":8080/public" + this.listIconSlug; 
        }
    },
    mounted() {
    },
    props: ['listIconSlug','size'],
};
</script>
<style scoped>
.large {
    height:50px;
    width:50px;
    border: 2px solid #666692;
    border-radius: 5px;
}
.small {
    height:30px;
    width:30px;
    border: 1px solid #666692;
    border-radius: 3px;
}
</style>